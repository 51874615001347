// Bar chart
jQuery(document).ready(function ($) {

    const housing_finance = $(".persentage-div").data('housing_finance');
    const lonely_isolated = $(".persentage-div").data('lonely_isolated');
    const stressed_anxious_needing_emotional_support = $(".persentage-div").data('stressed_anxious_needing_emotional_support');
    const groups_activities = $(".persentage-div").data('groups_activities');
    const awaiting_contact = $(".persentage-div").data('awaiting_contact');
    const physical_health = $(".persentage-div").data('physical_health');
    // Bar chart
    new Chart(document.getElementById("persentage"), {
        type: 'pie',
        data: {
            labels: ["Housing Finance", "Lonely Isolated", "Stressed Anxious Needing Emotional Support", "Groups Activities", "Awaiting Contact", "Physical Health"],
            datasets: [{
                label: "Percent",
                backgroundColor: ["#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9", "#c45850"],
                data: [
                    housing_finance,
                    lonely_isolated,
                    stressed_anxious_needing_emotional_support,
                    groups_activities,
                    awaiting_contact,
                    physical_health,
                ]
            }]
        },
        options: {
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: 'Client Introductions'
              }
            }
          },
    });


    const carer = $(".success-div").data('carer');
    const cmht = $(".success-div").data('cmht');
    const gp_practice = $(".success-div").data('gp_practice');
    const national_screening_programme = $(".success-div").data('national_screening_programme');
    const other_primary_health_care = $(".success-div").data('other_primary_health_care');
    const other_professional = $(".success-div").data('other_professional');
    const self_referral = $(".success-div").data('self_referral');
    const third_party = $(".success-div").data('third_party');
    // Bar chart
    new Chart(document.getElementById("introductions"), {
        type: 'bar',
        data: {
            labels: ["Carer", "CMHT", "GP Practice", "National Screening Programme", "Other Primary Health Care", "Other Professional", "Self - Referral", "Third Party"],
            datasets: [{
                label: "Population (millions)",
                backgroundColor: ["#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9", "#c45850"],
                data: [carer,
                    cmht,
                    gp_practice,
                    national_screening_programme,
                    other_primary_health_care,
                    other_professional,
                    self_referral,
                    third_party,
                ]
            }]
        },
        options: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: 'Source of Introductions'
            }
        }
    });

    const mental_health__emotional_support = $(".signposted-div").data('mental_health__emotional_support');
    const money = $(".signposted-div").data('money');
    const housing_support = $(".signposted-div").data('housing_support');
    const local_neighbourhood_groups = $(".signposted-div").data('local_neighbourhood_groups');
    const arts_based_activities_and_groups = $(".signposted-div").data('arts_based_activities_and_groups');
    const practial_support = $(".signposted-div").data('practial_support');
    const physical_activity_support = $(".signposted-div").data('physical_activity_support');
    const employment_support = $(".signposted-div").data('employment_support');
    const statutory_services = $(".signposted-div").data('statutory_services');
    const adult_learning_classes = $(".signposted-div").data('adult_learning_classes');
    const access_to_nature = $(".signposted-div").data('access_to_nature');

    new Chart(document.getElementById("signposted"), {
        type: 'horizontalBar',
        data: {
            labels: ["Mental health / Emotional support", "Money & Welfare Rights", "Housing Support", "Local Neighbourhood Groups", "Arts-based Activities and groups", "Practial Support (e.g. food banks)", "Physical activity support", "Employment Support", "Statutory Services", "Adult Learning Classes", "Access to Nature"],
            datasets: [{
                label: "Introductions per 1000 Population",
                backgroundColor: ["#82368c", "#39c7c7", "#223d78", "#404a4d", "#82368c", "#39c7c7", "#223d78", "#404a4d", "#82368c", "#39c7c7", "#223d78", "#404a4d", "#82368c", "#39c7c7", "#223d78", "#404a4d", "#82368c", "#39c7c7", "#223d78", "#404a4d", ],
                data: [
                    mental_health__emotional_support,
                    money,
                    housing_support,
                    local_neighbourhood_groups,arts_based_activities_and_groups,
                    practial_support,
                    physical_activity_support,
                    employment_support,
                    statutory_services,
                    adult_learning_classes,
                    access_to_nature,
                ]
            }]
        },
        options: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: 'Where We Signposted Clients'
            }
        }
    });

    const ls25__26 = $(".data-div").data('ls25');
    const crossgates = $(".data-div").data('crossgates');
    const out_of_area = $(".data-div").data('out_of_area');
    const bramley_wortle = $(".data-div").data('bramley_wortle');
    const woodsley = $(".data-div").data('woodsley');
    const morley_and_district = $(".data-div").data('morley_and_district');
    const holt_park = $(".data-div").data('holt_park');
    const seacroft = $(".data-div").data('seacroft');
    const centeral_north = $(".data-div").data('centeral_north');
    const west_leeds = $(".data-div").data('west_leeds');
    const chapeltown = $(".data-div").data('chapeltown');
    const middleton = $(".data-div").data('middleton');
    const york_road = $(".data-div").data('york_road');
    const beeston = $(".data-div").data('beeston');
    const weatherby = $(".data-div").data('weatherby');
    const yeadon = $(".data-div").data('yeadon');
    const armley = $(".data-div").data('armley');
    const lsmp = $(".data-div").data('lsmp');
    const otley = $(".data-div").data('otley');


    new Chart(document.getElementById("source"), {
        type: 'horizontalBar',
        data: {
            labels: ["ls25 / 26", "Crossgates ", "Out of Area ", "Bramley Wortley & Middleton", "Woodsley", "Morley and District", "Holt Park", "Seacroft", "Centeral North", "West Leeds", "Chapeltown", "Middleton & Hunslet", "York Road", "beeston", "weatherby", "Yeadon", "Armley", "LSMP & The Light", "Otley"],
            datasets: [{
                label: "Introductions per 1000 Population",
                backgroundColor: ["#82368c", "#39c7c7", "#223d78", "#404a4d", "#82368c", "#39c7c7", "#223d78", "#404a4d", "#82368c", "#39c7c7", "#223d78", "#404a4d", "#82368c", "#39c7c7", "#223d78", "#404a4d", "#82368c", "#39c7c7", "#223d78", "#404a4d", ],
                data: [
                    ls25__26,
                    crossgates,
                    out_of_area,
                    bramley_wortle,
                    woodsley,
                    morley_and_district,
                    holt_park,
                    seacroft,
                    centeral_north,
                    west_leeds,
                    chapeltown,
                    middleton,
                    york_road,
                    beeston,
                    weatherby,
                    yeadon,
                    armley,
                    lsmp,
                    otley
                ]
            }]
        },
        options: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: 'Introductions per 1000 Population'
            }
        }
    });

});