jQuery(document).ready(function ($) {
    if ($('#support').length) {
        
        $('#need-help-button').click(() => {
    
            $('.article').addClass('hide');
            $('#need-help').removeClass('hide');

            $('html, body').animate({
                scrollTop: $('#need-help').offset().top
            }, 800, function () {
                window.location.hash = '#need-help';
            });
        })
        $('#information-for-gp-button').click(() => {
            $('.article').addClass('hide');
            $('#information-for-gp').removeClass('hide');
            $('html, body').animate({
                scrollTop: $('#information-for-gp').offset().top
            }, 800, function () {
                window.location.hash = '#information-for-gp';
            });
        })
        $('#other-services-button').click(() => {
            $('.article').addClass('hide');
            $('#other-services').removeClass('hide');
            $('html, body').animate({
                scrollTop: $('#other-services').offset().top
            }, 800, function () {
                window.location.hash = '#other-services';
            });
        })
    }
    if ($('#referral').length) {
        $('#refer-friend-button').click(() => {
    
            $('.hide-form').addClass('hide');
            $('#refer-friend').removeClass('hide');

            $('html, body').animate({
                scrollTop: $('#refer-friend').offset().top
            }, 800, function () {
                window.location.hash = '#refer-friend';
            });
        })
        $('#help-button').click(() => {
    
            $('.hide-form').addClass('hide');
            $('#refer-friend').removeClass('hide');

            $('html, body').animate({
                scrollTop: $('#refer-friend').offset().top
            }, 800, function () {
                window.location.hash = '#refer-friend';
            });
        })
        $('#gp-button').click(() => {
            $('.hide-form').addClass('hide');
            $('#gp-form').removeClass('hide');
            $('html, body').animate({
                scrollTop: $('#gp-form').offset().top
            }, 800, function () {
                window.location.hash = '#gp-form';
            });
        })
        $('#for-my-self').click(() => {
            $('.hide-form').addClass('hide');
            $('#whofor').addClass('hide');
            $('#other-services').removeClass('hide');
            $('html, body').animate({
                scrollTop: $('#other-services').offset().top
            }, 800, function () {
                window.location.hash = '#other-services';
            });
        })

        $('#for-someone-else').click(() => {
            $('.hide-form').addClass('hide');
            $('#whofor').removeClass('hide');
        })

     
    }
})



AOS.init({
    duration: 1000,
    easing: 'ease-in-sine',
    delay: 70,
});