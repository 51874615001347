jQuery(document).ready(function ($) {
	if ($("#map").length) {
		title = "";

		function update(location) {
			$("#title").text(`The ${title} Team`);
			$(".team-area-card").addClass("hide");
			$("#team-member").removeClass("hide");

			$(location).removeClass("hide");
		}
		$("#ls25").click(() => {
			$(".active-team").removeClass("active-team");
			$("#ls25").addClass("active-team");

			title = "LS25 / 26";
			update(".ls25");
		});
		$("#wetherby").click(() => {
			$(".active-team").removeClass("active-team");
			$("#wetherby").addClass("active-team");

			title = "Wetherby";
			update(".wetherby");
		});
		$("#seacroft").click(() => {
			$(".active-team").removeClass("active-team");
			$("#seacroft").addClass("active-team");

			title = "Seacroft";
			update(".seacroft");
		});
		$("#middleton").click(() => {
			$(".active-team").removeClass("active-team");
			$("#middleton").addClass("active-team");

			title = "Hunslet & Middleton";
			update(".middleton");
		});
		$("#morley").click(() => {
			$(".active-team").removeClass("active-team");
			$("#morley").addClass("active-team");

			title = "Morley & District";
			update(".morley");
		});
		$("#bramley").click(() => {
			$(".active-team").removeClass("active-team");
			$("#bramley").addClass("active-team");

			title = "Bramley & Wortley";
			update(".bramley");
		});
		$("#westleeds").click(() => {
			$(".active-team").removeClass("active-team");
			$("#westleeds").addClass("active-team");

			title = "West Leeds";
			update(".west");
		});
		$("#woodsley").click(() => {
			$(".active-team").removeClass("active-team");
			$("#woodsley").addClass("active-team");

			title = "Woodsley";
			update(".woodsley");
		});
		$("#yorkroad").click(() => {
			$(".active-team").removeClass("active-team");
			$("#yorkroad").addClass("active-team");

			title = "York Road";
			update(".york");
		});
		$("#light").click(() => {
			$(".active-team").removeClass("active-team");
			$("#light").addClass("active-team");

			title = "LSMP & The Light";
			update(".lsmp");
		});
		$("#centralNorth").click(() => {
			$(".active-team").removeClass("active-team");
			$("#centralNorth").addClass("active-team");

			title = "Central North";
			update(".central");
		});
		$("#Crossgates").click(() => {
			$(".active-team").removeClass("active-team");
			$("#Crossgates").addClass("active-team");

			title = "Crossgates";
			update(".crossgates");
		});
		$("#otley").click(() => {
			$(".active-team").removeClass("active-team");
			$("#otley").addClass("active-team");

			title = "Otley";
			update(".otley");
		});
		$("#holt-park").click(() => {
			$(".active-team").removeClass("active-team");
			$("#holt-park").addClass("active-team");

			title = "Holt Park";
			update(".holt");
		});
		$("#yeadon").click(() => {
			$(".active-team").removeClass("active-team");
			$("#yeadon").addClass("active-team");

			title = "Yeadon";
			update(".yeadon");
		});
		$("#armley").click(() => {
			$(".active-team").removeClass("active-team");
			$("#armley").addClass("active-team");

			title = "Armley";
			update(".armley");
		});
		$("#beeston").click(() => {
			$(".active-team").removeClass("active-team");
			$("#beeston").addClass("active-team");

			title = "Beeston";
			update(".beeston");
		});
		$("#chapeltown").click(() => {
			$(".active-team").removeClass("active-team");
			$("#chapeltown").addClass("active-team");

			title = "Chapeltown";
			update(".chapeltown");
		});
		$("#harehills").click(() => {
			$(".active-team").removeClass("active-team");
			$("#harehills").addClass("active-team");

			title = "Harehills, Bermantofts & Richmond Hill";
			update(".harehills");
		});
		$("#area").change(function () {
			let value = $(this).val();
			title = this.options[this.selectedIndex].text;
			update("." + value);
		});
	}
	$(".face").click(function () {
		let area = $(this).data("area");
		console.log(area);

		if (area == "Area A") {
			$(".active-team").removeClass("active-team");

			$("#seacroft").addClass("active-team");
			$("#Crossgates").addClass("active-team");
			$("#yorkroad").addClass("active-team");
			$("#light").addClass("active-team");
			$("#woodsley").addClass("active-team");
			$("#centralNorth").addClass("active-team");
			$("#holt-park").addClass("active-team");
		}
		if (area == "Area B") {
			$(".active-team").removeClass("active-team");

			$("#westleeds").addClass("active-team");
			$("#wetherby").addClass("active-team");
			$("#ls25").addClass("active-team");
			$("#morley").addClass("active-team");
			$("#otley").addClass("active-team");
			$("#yeadon  ").addClass("active-team");
		}
		if (area == "Area C") {
			$(".active-team").removeClass("active-team");

			$("#armley").addClass("active-team");
			$("#bramley").addClass("active-team");
			$("#chapeltown").addClass("active-team");
			$("#harehills").addClass("active-team");
			$("#middleton").addClass("active-team");
			$("#beeston").addClass("active-team");
		}
		$("html, body").animate(
			{
				scrollTop: $("#Group_289").offset().top,
			},
			800
		);
	});
	$(".team-area-card-face").click(function () {
		let area = $(this).data("area");

		if (area == "Area A") {
			$(".active-team").removeClass("active-team");

			$("#seacroft").addClass("active-team");
			$("#Crossgates").addClass("active-team");
			$("#yorkroad").addClass("active-team");
			$("#light").addClass("active-team");
			$("#woodsley").addClass("active-team");
			$("#centralNorth").addClass("active-team");
			$("#westleeds").addClass("active-team");
			$("#holt-park").addClass("active-team");
		}
		if (area == "Area B") {
			$(".active-team").removeClass("active-team");

			$("#wetherby").addClass("active-team");
			$("#ls25").addClass("active-team");
			$("#morley").addClass("active-team");
			$("#otley").addClass("active-team");
			$("#yeadon  ").addClass("active-team");
		}
		if (area == "Area C") {
			$(".active-team").removeClass("active-team");

			$("#armley").addClass("active-team");
			$("#bramley").addClass("active-team");
			$("#chapeltown").addClass("active-team");
			$("#harehills").addClass("active-team");
			$("#middleton").addClass("active-team");
			$("#beeston").addClass("active-team");
		}

		$("#team-member").addClass("hide");

		$("html, body").animate(
			{
				scrollTop: $("#Group_289").offset().top,
			},
			200
		);
	});
});
