jQuery(document).ready(function ($) {
	let page = 1;
	// get amount of pages in form
	const max = $(".form .form").length;
	// on click shows next page
	$("#next").click(() => {
		check();
		// page += 1;
		// update();
	});
	// on click shows previous page
	$("#previous").click(() => {
		page -= 1;
		update();
	});
	//previous button on last page
	$("#previous-btn").click((event) => {
		page -= 1;
		update();
		event.preventDefault();
	});
	let formPage = "#other-services";
	let form = formPage + " .form" + page;
	let percent = 0;
	let amount = $(".form1 input").length;
	let pagelength = 1;
	let oneinput = 100 / amount / pagelength;
	let check = () => {
		let error = false;
		$(".rederror").remove();
		$(form + " .required").each(function (i, element) {
			$(this).after("");

			if ($(element).hasClass("email")) {
				const re =
					/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				if (re.test(element.value)) {
					$(this).removeClass("error");
				} else {
					error = true;
					$(this).addClass("error");
					$(this).after("<p class='rederror'>Please enter a valid Email address</p>");
				}
			} else if ($(element).hasClass("number")) {
				var regex = /\d{5}$/;
				cleanreg = /\D/g;
				let clean = element.value.replace(cleanreg, "");

				if (regex.test(clean)) {
					$(this).removeClass("error");
					$(this).val(clean);
				} else {
					error = true;
					$(this).addClass("error");
					$(this).after("<p class='rederror'>Please enter a valid number</p>");
				}
			}
			if (element.value == "") {
				$(this).addClass("error");
				$(this).after("<p class='rederror'>This is a required field</p>");
				error = true;
			}
		});
		$(form + " input").each(function (i, element) {
			if (!$(element).hasClass("required")) {
				if ($(element).hasClass("email")) {
					const re =
						/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					if (re.test(element.value)) {
						$(this).removeClass("error");
						$(this).after("");
					} else {
						if ($(this).val()) {
							error = true;
							$(this).addClass("error");
							$(this).after("<p>Please enter a valid Email address</p>");
						}
					}
				}
				if ($(element).hasClass("number")) {
					var regex = /\d{5}$/;
					cleanreg = /\D/g;
					let clean = element.value.replace(cleanreg, "");

					if (regex.test(clean)) {
						$(this).removeClass("error");
						$(this).after("");
						$(this).val(clean);
					} else {
						if ($(this).val()) {
							error = true;
							$(this).addClass("error");
							$(this).after("<p class='rederror'>Please enter a valid number</p>");
						}
					}
				}
			}
		});
		if (error == false) {
			page += 1;
			$(".error").removeClass("error");
			$(".rederror").remove();
			update();
		} else {
			$("html, body").animate(
				{
					scrollTop: 100,
				},
				"slow"
			);
			$(".error-message").removeClass("hide");
		}
	};
	let update = () => {
		form = formPage + " .form" + page;
		circle = ".circle" + page;
		percent = (page - 1) * (100 / pagelength);

		amount = $(form + " input").length;
		oneinput = 100 / amount / pagelength;
		$(".show").removeClass("show");
		$(form).addClass("show");
		$(circle).addClass("active");

		$("html, body").animate(
			{
				scrollTop: $(form).offset().top - 100,
			},
			1
		);
		if (page == 1) {
			$("#previous").css("display", "none");
		} else {
			$("#previous").css("display", "inline-block");
		}
		if (page == 4) {
			$("#test").css("display", "none");
			$("#gp-form").css("margin-bottom", "0");
			$(".opacity").css("opacity", "0");
		} else {
			$("#test").css("display", "inline-block");
			$("#gp-form").css("margin-bottom", "3rem");
			$(".opacity").css("opacity", "1");
		}
		// $("html, body").animate({
		//     scrollTop: 50
		// }, "slow");
	};
	$("input").change(function () {
		let filled = 0;
		let tempPercent = percent;

		$(form + " input").each(function (i, element) {
			if (element.value) {
				filled += 1;
			}
			tempPercent = Math.round(percent + filled * oneinput);
		});
		if (tempPercent > 100) {
			tempPercent = 100;
		}
		$(".fill").css("width", tempPercent + "%");
		$(".percent").text(tempPercent + "%");
	});

	$(".wpcf7-list-item").each(function () {
		if ($(this).find("input").prop("checked")) {
			$(this).find(".wpcf7-list-item-label").addClass("active");
		} else {
			$(this).find(".wpcf7-list-item-label").removeClass("active");
		}
	});
	$(".wpcf7-list-item-label").click(function () {
		setTimeout(() => {
			$(".wpcf7-list-item").each(function () {
				if ($(this).find("input").prop("checked")) {
					$(this).find(".wpcf7-list-item-label").addClass("active");
				} else {
					$(this).find(".wpcf7-list-item-label").removeClass("active");
				}
			});
		}, 20);
	});
	//add star rating
	$(".easy .wpcf7-list-item-label").click(function () {
		let star = 0;
		setTimeout(() => {
			// loop thought
			$(".easy .wpcf7-list-item").each(function () {
				// add star to everything
				$(this).find(".wpcf7-list-item-label").addClass("activeStar");
				if (star >= 1) {
					$(this).find(".wpcf7-list-item-label").removeClass("activeStar");
				}
				// once checked if found start removing stars
				if ($(this).find("input").prop("checked")) {
					star += 1;
				}
			});
		}, 20);
	});
	let thankyoupage = "";
	$("#refer-friend-button").click(() => {
		sessionStorage.clear();
		formPage = "#refer-friend";
		form = formPage + " .form1";
		$(".fill").css("width", "0%");
		pagelength = 1;
		amount = $(form + " input").length;
		oneinput = 100 / amount / pagelength;
		thankyoupage = "refer-friend";
	});
	$("#help-button").click(() => {
		sessionStorage.clear();
		formPage = "#refer-friend";
		form = formPage + " .form1";
		$(".fill").css("width", "0%");
		pagelength = 1;
		amount = $(form + " input").length;
		oneinput = 100 / amount / pagelength;
		thankyoupage = "refer-friend";
	});
	$("#gp-button").click(() => {
		sessionStorage.clear();
		formPage = "#gp-form";
		form = formPage + " .form1";
		$(".fill").css("width", "0%");
		pagelength = 4;
		amount = $(form + " input").length;
		oneinput = 100 / amount / pagelength;
		thankyoupage = "gp-form";
	});
	$("#only-primary-care-button").click(() => {
		console.log("#only-primary-care-button CLICKED");
		$("#backdrop").toggleClass("visible");
	});
	$("#backdrop").click(() => {
		console.log("#backdrop CLICKED");
		$("#backdrop").toggleClass("visible");
	});
	$("#for-my-self").click(() => {
		sessionStorage.clear();
		formPage = "#other-services";
		form = formPage + " .form1";
		$(".fill").css("width", "0%");
		pagelength = 1;
		amount = $(form + " input").length;
		oneinput = 100 / amount / pagelength;
		thankyoupage = "for-my-self";
	});
	if ($("#wpcf7-f70-o1").length) {
		thankyoupage = "contact";
	}

	if ($(".wpcf7").length) {
		document.addEventListener(
			"wpcf7mailsent",
			function (event) {
				location = `https://linkingleeds.com/thank-you?subject=${thankyoupage}`;
			},
			false
		);
	}

	let dobday = $(".daybirth input").val();
	let dobmonth = $(".monthbirth input").val();
	let dobyear = $(".yearbirth input").val();
	$(".daybirth input").keyup(function () {
		dobday = $(this).val();
		checkdate();
	});
	$(".monthbirth input").keyup(function () {
		dobmonth = $(this).val();
		checkdate();
	});
	$(".yearbirth input").keyup(function () {
		dobyear = $(this).val();
		checkdate();
	});
	$(".daybirth input").change(function () {
		dobday = $(this).val();
		checkdate();
	});
	$(".monthbirth input").change(function () {
		dobmonth = $(this).val();
		checkdate();
	});
	$(".yearbirth input").change(function () {
		dobyear = $(this).val();
		checkdate();
	});
	let dontsubmit = false;
	$("body").keypress(function (event) {
		if (event.which == 13 && dontsubmit) {
			event.preventDefault();
		}
	});
	function checkdate() {
		if (dobday && dobmonth && dobyear) {
			var now = parseInt(new Date().toISOString().slice(0, 10).replace(/-/g, ""));
			var dob = dobyear * 10000 + dobmonth * 100 + dobday * 1; // Coerces strings to integers
			if (now - dob < 160000) {
				console.log("now");
				$(".doberror").text("Must be at lest 16 years old");
				$(".doberror").show();
				$(".wpcf7-submit").hide();
				$("#next").hide();
				dontsubmit = true;
			} else {
				$(".doberror").hide();
				$(".wpcf7-submit").show();
				$("#next").show();

				dontsubmit = false;
			}
		}
	}
	checkdate();
});
